<template>
  <div class="en-m-header">
    <img @click="handleBack" src="@/assets/icon_back@2x.png" alt="back" v-if="isBack">
    <img @click="$emit('close', true)" src="@/assets/close.png" alt="back" v-else>
    <p>{{title}}</p>
    <span><slot name="right"></slot></span>
  </div>
</template>

<script>
export default {
  name: "OrderHeader",
  props: {
    title: {
      default: ''
    },
    isBack: {
      default: true
    }
  },
  methods: {
    handleBack(){
      localStorage.removeItem('bargain')
      if(this.$route.path != '/en/pay') {
        if(this.$route.query.cart == 1) {
          this.$router.push('/en')
        } else {
          this.$router.go(-1)
        }
      } else {
        this.$router.push('/en/order/0')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.en-m-header {
  width: 100%;
  height: 44px;
  background-color: #EBF1FF;
  padding: 0 12px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  &>img:nth-of-type(1){
    width: 5.86667vw;
    height: 5.86667vw;
    position: relative;
    z-index: 8;
  }
  &>span {
    position: relative;
    z-index: 8;
    min-width: 9.33333vw;
    &>img{
      width: 8vw;
      height: 8vw;
    }
    &>span,&>span>a{
      font-size:4.26667vw;
      font-weight:500;
      color: #333;
    }
  }
  &>p {
    font-size:4.26667vw;
    font-weight:400;
    color:rgba(51,51,51,1);
    line-height:6.66667vw;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    line-height: 11.73333vw;
    padding: 0 21.33333vw;
  }
}
</style>