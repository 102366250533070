/** 
 *   首页
 */
import { post,get } from '@/untils/js/axios.js'



// 获取手机验证码
export const phone_code = params => post('/sms/sendcode', params)

// 验证 手机验证码
export const phone_code_check = params => post('/sms/check-code', params)

// 获取邮箱验证码
export const email_code = params => post('/email/sendcode', params)

// 验证 手机验证码
export const email_code_check = params => post('/email/check-code', params)


// 手机验证码登录注册
export const phone_login = params => post('/login/phone', params)

// 邮箱验证码登录注册
export const email_login = params => post('/login/email', params)

// 账号密码登录
export const login       = params => post('/login', params)

// 微信登录
export const wx_login    = params => post('/login/wechat', params)

// INS登录
export const ins_login   = params => post('/login/instagram', params)

// FaceBook登录
export const fb_login    = params => post('/login/facebook', params)

// 游客登录
export const guest       = params => post('/login/visitor', params)

// 游客查单
export const getOrderDetail = params => post('/guest/check-order', params)

// openid   activity_id 
// 登陆兑换 
export const getLogin = params => post('luckdraw/login-phone', params)

// 
export const user_login_info = params => get('user_login_info', params)


