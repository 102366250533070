import { get, post, put, del } from '@/untils/js/axios.js'
/** 
 *   地址
 */

// 城市列表
export const city        = params => get('/city/getcity', params)


// 个人地址 列表
export const address     = params => get('/useraddrs', params)

// 个人地址 添加
export const address_add = params => post('/useraddrs', params)

// 个人地址 修改
export const address_put = (id,params) => put(`/useraddrs/${id}`, params)

// 个人地址 删除
export const address_del = params => del(`/useraddrs/${params}`)


// 用户 绑定邮箱
export const bindEmail = params => post('/account/bindemail', params)

// 用户 修改密码
export const repass = params => post('/account/repass', params)
// 用户 绑定查询
export const userBind = params => get('/account/bind-query', params)

// 用户绑定手机号
export const bindPhone = params => post('/account/bindphone', params) 

// 用户信息
export const getAccountInfo = params => get('/account/info', params)


